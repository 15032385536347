/* Grid container styling */
.grid-container {
  display: grid;
  grid-template-columns: repeat(12s, 1fr);
  grid-template-rows: repeat(11, 1fr);
  gap: 5px;
  max-width: 95%;
  overflow: hidden;
}

/* Counter container styling */
.counter-container {
  display: flex;
  align-items: center;
}

.counter-container.vertical {
  flex-direction: column;
}

.counter-container.horizontal {
  flex-direction: row;
}

/* Individual counter styling */
.counter {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000;
  padding: 5px;
  background-color: var(--counter-bg-color, #586b9a);
  /* default to #f3f3f3 if not provided */
}

.counter>span {
  margin: 5px;
}

/* Button styling */
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  opacity: 0.9;
  /* Makes the button slightly transparent to reveal the counter color */
}

.button.increment {
  background-color: rgba(0, 255, 0, 0.3);
  /* Light green tint */
}

.button.decrement {
  background-color: rgba(255, 0, 0, 0.3);
  /* Light red tint */
}

.button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.button:hover:not(:disabled) {
  background-color: #0056b3;
}